import { Grid } from "@mui/material";
import { graphql, PageProps } from "gatsby";
import { isNil } from "lodash";
import { useMemo } from "react";
import InViewAnimationGrid from "../components/behaviors/InViewAnimation/InViewAnimationGrid";
import PageTransitionWrapper from "../components/behaviors/PageTransitionWrapper/PageTransitionWrapper";
import Image from "../components/elements/Image/Image";
import Link from "../components/elements/Link/Link";
import PageSpacerWrapper from "../components/elements/PageSpacerWrapper/PageSpacerWrapper";
import RichText from "../components/elements/RichText/RichText";
import Seo from "../components/seo";
import ContactForm from "../components/views/ContactForm/ContactForm";
import { columnSpacing } from "../theme/Theme";
import { buildImageData } from "../utils/image/buildImageData";
import { buildLink } from "../utils/link/buildLink";

const Contact = ({ data }: PageProps<GatsbyTypes.ContactPageQuery>) => {
  const pageData = data?.prismicContactPage?.data;

  const links = useMemo(
    () =>
      pageData?.icon_links?.map(({ link, image }) => ({
        link: buildLink(link),
        image: buildImageData(image),
      })),
    [pageData]
  );

  return (
    <PageTransitionWrapper pageId="contact-page">
      <Seo
        title={pageData?.seo_title}
        description={pageData?.seo_description}
      />
      <PageSpacerWrapper>
        <Grid
          container
          rowSpacing={{ xs: 6 }}
          columnSpacing={{ ...columnSpacing, sm: 15, lg: 20 }}
          sx={{
            marginTop: {
              xs: 10,
              sm: 12,
            },
          }}
        >
          <InViewAnimationGrid container item md={5} alignContent="flex-start">
            {!isNil(pageData?.description?.html) && (
              <RichText text={pageData?.description?.html} />
            )}
            <Grid item>
              {links?.map(({ image, link }) => (
                <Link
                  sx={{ "& img": { maxHeight: "36px", width: "auto" }, mr: 1 }}
                  key={link}
                  href={link}
                >
                  <Image alt={image.alt} image={image.image} />
                </Link>
              ))}
            </Grid>
          </InViewAnimationGrid>
          <InViewAnimationGrid
            container
            item
            sx={{
              maxWidth: {
                lg: 500,
              },
            }}
            rowSpacing={{ xs: 5, sm: 8, md: 10 }}
            md={6}
            lg={5}
          >
            <Grid item xs={12}>
              <RichText
                text={pageData?.form_description?.html}
                textProps={{ textAlign: "center", width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <ContactForm />
            </Grid>
          </InViewAnimationGrid>
        </Grid>
      </PageSpacerWrapper>
    </PageTransitionWrapper>
  );
};

export default Contact;

export const query = graphql`
  query ContactPage {
    prismicContactPage {
      uid
      data {
        description {
          html
        }
        form_description {
          html
        }
        icon_links {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          link {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
        }
        seo_description
        seo_title
      }
    }
  }
`;
